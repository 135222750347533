import betonred from '../img/betonred.png'



export const BestOffer =
{
    titel: 'Bet On red',
    img: betonred,
    link: 'https://track.betmenaffiliates.com/visit/?bta=37291&nci=5345&utm_campaign=341149',
    offer1: '',
    offer2: '',
    offer3: '100% Bonus to to 150€',
    offer4: '150 FS',
    offer5: 'Wager 35x',
    "MGA": false,
    "CUR": true,
    "CR": false,
    "width": 300,
    "height":250,
    "NEW": true,
}

